<template>
  <div
    class="hero"
    :style="{
      backgroundImage: backgroundImage,
      height: height ? height : isTiny ? '450px' : '',
      backgroundPosition: isPlayersHero ? 'left bottom' : '',
      backgroundSize: isPlayersHero ? 'auto' : '',
    }"
    :class="subtitle ? 'has-subtitle' : ''"
  >
    <h1 class="title" ref="title" :style="{ color: titleColor, fontSize: isTiny ? '8rem' : '' }">{{ title }}</h1>
    <h2 class="subtitle" :style="{ color: titleColor }" v-if="subtitle">
      {{ subtitle }}
    </h2>
  </div>

  <div class="content-wrapper" v-if="hasSlotContent">
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "hero",

  props: {
    title: String,
    titleColor: String,
    subtitle: String,
    bgImage: String,
    isTiny: Boolean,
    isPlayersHero: Boolean,
    height: String,
  },

  data() {
    return {
      backgroundImage: `url(${require("@/assets/img/hero/" + this.bgImage)})`,
      subtitleWidth: null,
    };
  },

  computed: {
    hasSlotContent() {
      return !!this.$slots.default;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  height: 700px;
  // padding: 8%;
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  margin-top: clamp(40px, 3%, 80px);
  padding-block: clamp(10px, 6%, 100px);
  padding-inline: clamp(30px, 8%, 200px);

  .title {
    display: inline-block;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 10rem;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1;
    margin: 0;
  }

  .subtitle {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
  }
}

.content-wrapper {
  padding: 0 8%;
  position: relative;
  bottom: 140px;
  margin-bottom: -140px;

  .content {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
  }
}

@media only screen and (max-width: 1292px) {
  .hero {
    padding-block: 8%;
  }
}

@media only screen and (max-width: 1200px) {
  .hero {
    padding: 8% 4%;
    height: 500px;

    &.has-subtitle {
      height: 600px;
    }

    .title {
      font-size: 140px;
    }

    .subtitle {
      width: 100% !important;
      margin-top: -10px;
    }
  }

  .content-wrapper {
    padding: 0 4%;
  }
}

@media only screen and (max-width: 980px) {
  .hero {
    height: 300px !important;

    &.has-subtitle {
      height: 500px;
    }

    .title {
      font-size: 80px !important;
    }

    .subtitle {
      font-size: 25px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .hero {
    padding-block: 50px;
  }

  .content-wrapper {
    padding: 0 5px 40px;

    .content {
      padding: 10px;
    }
  }
}

@media only screen and (max-width: 550px) {
  .subtitle {
    max-width: none;
  }
}
</style>
