<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />

  <hero :title="$t('standings.title')" bg-image="bnx_slider_4-min-scaled.jpg" :is-tiny="true"></hero>
  <div class="content-wrapper">
    <div class="content">
      <h3>BNXT {{ filters.season - 1 }}-{{ filters.season }} {{ $t("standings.section.title") }}</h3>

      <div class="filter-wrapper">
        <TeamFilter :is-standings="true" @filters="filterTeams($event)" :show-month-selector="false" />
      </div>

      <div class="table-wrapper" v-if="isStandingsVisible">
        <table class="table table-bordered bnxt-table">
          <thead>
            <tr>
              <th style="text-align: center">#</th>
              <th style="text-align: center">Team</th>
              <th style="text-align: center">Games</th>
              <th style="text-align: center">WON</th>
              <th style="text-align: center">LOST</th>

              <th style="text-align: center" v-if="filters.season >= 2025">%</th>
              <th style="text-align: center" v-else>POINTS</th>
              <th style="text-align: center">+/-</th>
              <th style="text-align: center">DIFF</th>
              <th style="text-align: center">HOME</th>
              <th style="text-align: center">AWAY</th>
              <th style="text-align: center">LAST-5</th>
              <th style="text-align: center">STREAK</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in standings">
              <td>{{ row.position }}</td>
              <td class="team">
                <router-link
                  :to="{
                    name: misc.getRouteNameByLocale($router.options.routes, 'Team Statistics'),
                    query: { team_id: row.team.id },
                  }"
                >
                  <div class="logo-wrapper">
                    <img v-if="row.team.team_logo" :alt="row.team.team_logo.alt" :src="row.team.team_logo.public_url" />
                  </div>
                  {{ row.team.name }}
                </router-link>
              </td>
              <td>{{ row.played }}</td>
              <td>{{ row.win }}</td>
              <td>{{ row.loss }}</td>
              <td>
                <b v-if="filters.season >= 2025">{{ row.percent }}</b>
                <b v-else>{{ row.point }}</b>
              </td>
              <td>{{ row.score }} - {{ row.against }}</td>
              <td>{{ row.score_difference }}</td>
              <td>{{ row.win_loss.home.win }} - {{ row.win_loss.home.loss }}</td>
              <td>{{ row.win_loss.away.win }} - {{ row.win_loss.away.loss }}</td>
              <td>{{ row.last5.win }} - {{ row.last5.loss }}</td>
              <td><span v-if="!row.streak.is_win">-</span>{{ row.streak.count }}</td>
            </tr>
          </tbody>
        </table>
        <p v-if="filters.phaseName === 'Elite Silver'" class="silver-league-info">
          * The results of the BE-BE matchups in Elite Silver will be calculated into the overall standings, but in case
          of tied standings between two or more BE teams, only the results from the national phase are taken into
          consideration.
        </p>
        <p
          v-html="$t('standings_text')"
          v-if="filters.phaseName === 'Elite Silver' || filters.phaseName === 'Elite Gold'"
          class="silver-league-info"
        ></p>
      </div>
    </div>
  </div>
</template>
<script>
import TeamFilter from "@/components/simple/filters/team-filter/TeamFilter";
import Hero from "@/components/shared/hero";
import standingsService from "@/services/StandingsService";
import AppLoader from "@/components/shared/loader/AppLoader";
import misc from "@/core/helpers/misc";
export default {
  name: `Standings`,
  components: { TeamFilter, Hero, AppLoader },
  data() {
    return {
      standings: [],
      isStandingsVisible: false,
      filters: {
        season: process.env.VUE_APP_ACTIVE_SEASON,
        phase: null,
      },
      misc: misc,
      loaders: {
        standings: false,
      },
    };
  },

  methods: {
    async getStandings(competitionId, phaseId) {
      this.standings = await standingsService.getStandingsByCompetitionId(competitionId, phaseId);
      this.isStandingsVisible = true;
      this.loaders.standings = true;
    },

    filterTeams(filters) {
      this.loaders.standings = false;
      this.filters = { ...this.filters, ...filters };

      if (filters.isStandingsVisible) {
        this.getStandings(filters.competitionId, filters.phase);
      } else {
        this.loaders.standings = true;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.content-wrapper {
  background-color: #f2faff;

  .content {
    padding: 1.5% 8% 4%;

    h3 {
      color: #000;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
    }

    .filter-wrapper {
      width: 48%;
    }

    .table-wrapper {
      margin-top: 85px;

      table thead tr th {
        &:first-child {
          min-width: 74px;
        }
        &:nth-child(2) {
          min-width: 270px;
        }
        &:nth-child(7),
        &:nth-child(11) {
          min-width: 80px;
        }
      }

      .team {
        max-width: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        img {
          margin-right: 10px;
        }
      }

      .silver-league-info {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .content-wrapper {
    .content {
      padding: 1.5% 20px 4%;

      .filter-wrapper {
        width: 52%;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .content-wrapper {
    .content {
      .filter-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
