<template>
  <div class="pre-header">
    <h3>{{ $t("statistics.individual." + title) }}</h3>
    <a href="javascript:void(0)" @click="$emit('viewAll')"
      >{{ $t("statistics.view-all") }} <font-awesome-icon icon="fa-solid fa-arrow-right"></font-awesome-icon
    ></a>
  </div>

  <div class="statistics-box">
    <div class="header">
      <div class="player-tag"><sup>#</sup>1</div>

      <router-link
        :to="{
          name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
          query: { player_id: block[0].roster_id, team_id: block[0].team.id },
        }"
        class="player"
      >
        <img
          :src="block[0].player.profile_image.thumbnails.small"
          class="img-fluid player-img"
          v-if="block[0]?.player?.profile_image?.thumbnails?.small"
        />
        <img src="../../../assets/img/image_not_found.png" class="img-fluid player-img" v-else />
        <h4 class="player-name">
          {{ block[0].player.first_name }} <span>{{ block[0].player.last_name?.toUpperCase() }}</span>
        </h4>
        <div class="player-team">
          <img v-if="block[0].team.team_logo" :src="block[0].team.team_logo.thumbnails.small" alt="" />
          <span>{{ block[0].team.name }}</span>
        </div>
        <div class="player-points">
          <div class="text-center">{{ misc.formatNumber(block[0].value) }}</div>
        </div>
      </router-link>
    </div>
    <div class="content">
      <div class="player" v-for="(item, index) in block.slice(1)">
        <span class="rank">{{ index + 2 }}</span>
        <router-link
          :to="{
            name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
            query: { player_id: item.roster_id, team_id: item.team.id },
          }"
          class="player-image"
        >
          <img
            :src="item.player.profile_image.thumbnails.small"
            v-if="item?.player?.profile_image?.thumbnails?.small"
          />
          <img src="../../../assets/img/image_not_found.png" v-else />
        </router-link>
        <div class="player-details">
          <router-link
            :to="{
              name: misc.getRouteNameByLocale($router.options.routes, 'Player-profile'),
              query: { player_id: item.roster_id, team_id: item.team.id },
            }"
            class="player-name"
          >
            {{ item.player.first_name }} {{ item.player.last_name?.toUpperCase() }}
          </router-link>
          <div class="player-team">
            <img v-if="item.team.team_logo" :src="item.team.team_logo.thumbnails.small" alt="" />
            <span>{{ item.team.name }}</span>
          </div>
        </div>
        <div class="player-points">
          <div class="text-center">{{ misc.formatNumber(item.value) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "statistics-box",
  props: ["title", "block"],
  data() {
    return {
      boxImg: String,
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.pre-header {
  display: flex;
  justify-content: space-between;
  font-family: DrukWideBoldItalic, Sans-serif;

  h3 {
    color: #000;
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
  }

  a {
    font-size: 22px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;

    svg {
      color: #6441ff;
    }
  }
}

.stat-box-wrapper {
  &:nth-child(9n + 1) .header {
    background: url("../../../assets/img/statistics/bnx_bg-orange.jpg");
  }
  &:nth-child(9n + 2) .header {
    background: url("../../../assets/img/statistics/bnx_bg-green.jpg");
  }
  &:nth-child(9n + 3) .header {
    background: url("../../../assets/img/statistics/bnx_bg-darkblue.jpg");
  }
  &:nth-child(9n + 4) .header {
    background: url("../../../assets/img/statistics/bnx_bg-lightblue.jpg");
  }
  &:nth-child(9n + 5) .header {
    background: url("../../../assets/img/statistics/bnx_bg-pink.jpg");
  }
  &:nth-child(9n + 6) .header {
    background: url("../../../assets/img/statistics/bnx_bg-purple.jpg");
  }
  &:nth-child(9n + 7) .header {
    background: url("../../../assets/img/statistics/bnx_bg-red.jpg");
  }
  &:nth-child(9n + 8) .header {
    background: url("../../../assets/img/statistics/bnx_bg-black.jpg");
  }
  &:nth-child(9n + 9) .header {
    background: url("../../../assets/img/statistics/bnx_bg-blue.jpg");
  }
}

.statistics-box {
  background: #fff;
  color: #000;
  position: relative;
  z-index: 1;
  padding: 0;
  box-shadow: 0 5px 5px #def3ff;
  margin-bottom: 30px;
  border-radius: 10px;

  .header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 30px 20px 0;
    position: relative;

    .player-tag {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      position: absolute;
      left: 10px;
      color: #fff;
      top: 10px;
      font-size: 20px;
      font-weight: 800;
      z-index: 1;
      font-size: 25px;

      sup {
        margin-right: 3px;
      }
    }

    .player {
      position: relative;
      padding-left: 135px;
      height: 130px;
      left: -20px;
      display: block;
      text-decoration: none;

      &-img {
        max-width: 137px;
        max-height: 130px;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &-name {
        font-weight: 600;
        color: #fff;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        font-family: DrukWideBoldItalic, Sans-serif;
        text-transform: uppercase;
        font-size: 20px;

        span {
          display: block;
          font-size: 30px;
          font-weight: 800;
        }
      }

      &-team {
        font-size: 20px;
        font-family: DrukWideBoldItalic, Sans-serif;
        color: #fff;

        img {
          width: 25px;
        }

        span {
          vertical-align: middle;
          margin-left: 3px;
        }
      }

      &-points {
        font-size: 22px;
        color: #fff;
        font-family: DrukWide, Sans-serif;
        font-weight: 600;
        position: absolute;
        right: -20px;
        bottom: 10px;
      }

      .player-sub-points {
        font-size: 12px;
        text-align: center;
      }
    }
  }

  .content {
    padding-bottom: 30px;

    .player {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;

      .rank {
        display: inline-block;
        width: 30px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        font-family: DrukWide, Sans-serif;
        padding-top: 7px;
      }

      .player-image {
        img {
          max-width: 45px;
          max-height: 45px;
          width: auto;
          height: auto;
        }
      }

      .player-details {
        .player-name {
          text-decoration: none;
          color: #000;
          font-weight: 600;
          font-size: 15px;
        }

        .player-team {
          display: flex;
          gap: 10px;

          img {
            width: 15px;
            height: 15px;
          }

          span {
            font-family: DrukWideBoldItalic, Sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            width: 100%;
            display: flex;
            align-items: center;
            line-height: 16px;
          }
        }
      }

      .player-points {
        font-size: 14px;
        font-family: DrukWide;
        font-weight: 800;
        margin-left: auto;
        padding-right: 10px;
      }

      .player-sub-points {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
</style>
