<template>
  <div class="calendar">
    <table class="table">
      <thead>
        <tr>
          <th class="label">{{ $t("date_and_time") }}</th>
          <th class="label">{{ $t("phase") }}</th>
          <th class="label">{{ $t("teams") }}</th>
          <th class="label"></th>
          <th class="label">{{ $t("venue") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in schedules">
          <td class="date-time-wrapper">
            <div class="date">{{ moment(row.game_time).format("dddd, D MMM YYYY") }}</div>
            <div class="time">{{ moment(row.game_time).format("h:mma") }}</div>
          </td>

          <td class="phase-wrapper">{{ row.phase.name }}</td>

          <td class="teams-wrapper">
            <div class="team">
              <img
                v-if="row.competitors[0].competition_team.team_logo"
                :src="row.competitors[0].competition_team.team_logo.public_url"
                :alt="row.competitors[0].competition_team.team_logo.alt"
              />
              <p class="team-name">{{ row.competitors[0].competition_team.name }}</p>
            </div>
            <div class="team">
              <img
                v-if="row.competitors[1].competition_team.team_logo"
                :src="row.competitors[1].competition_team.team_logo.public_url"
                :alt="row.competitors[1].competition_team.team_logo.alt"
              />
              <p class="team-name">{{ row.competitors[1].competition_team.name }}</p>
            </div>
            <div class="button-row">
              <div
                class="button-wrapper"
                v-if="row?.competitors[0]?.competition_team?.ticket_url && row?.status !== 'finished'"
              >
                <a :href="row.competitors[0].competition_team.ticket_url" class="get-tickets-btn">
                  {{ $t("get_tickets") }}
                </a>
              </div>
              <div class="button-wrapper" v-if="row?.status !== 'finished'">
                <a href="https://www.bnxt.tv/" target="_blank" class="get-tickets-btn">
                  {{ $t("watch_live") }}
                </a>
              </div>
              <div class="button-wrapper" v-if="row?.status === 'finished'">
                <a href="https://www.bnxt.tv" target="_blank" class="get-tickets-btn">
                  {{ $t("game_report.watch_game_button") }}
                </a>
              </div>
            </div>
          </td>

          <td
            class="score-wrapper"
            v-if="(!row?.competitors[0]?.finalScore && !row?.competitors[1]?.finalScore) || row?.status !== 'finished'"
          >
            <div class="no-score"></div>
            <div class="no-score"></div>
          </td>

          <td
            class="score-wrapper"
            v-if="
              (row?.competitors[0]?.finalScore || row?.competitors[0]?.finalScore === 0) &&
              (row?.competitors[1]?.finalScore || row?.competitors[1]?.finalScore === 0) &&
              row?.status === 'finished'
            "
          >
            <router-link
              :to="{
                name: misc.getRouteNameByLocale($router.options.routes, 'Game report'),
                params: { gameId: row.id },
              }"
            >
              <div class="score">{{ row.competitors[0].finalScore }}</div>
              <div class="score">{{ row.competitors[1].finalScore }}</div>
            </router-link>
          </td>

          <td class="venue-wrapper">
            <div class="venue">{{ row.arena.name }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment/moment";
import misc from "@/core/helpers/misc";

export default {
  name: "calendar-table",
  props: ["schedules"],
  data() {
    return {
      misc: misc,
    };
  },
  computed: { moment: () => moment },
};
</script>

<style scoped lang="scss">
.calendar {
  overflow: auto;

  .button-row {
    display: flex;
  }

  table {
    thead {
      .label {
        font-family: DrukWide;
        text-transform: uppercase;
        padding-bottom: 20px;

        &:nth-child(1) {
          min-width: 250px;
          width: 20%;
        }

        &:nth-child(2) {
          min-width: 130px;
          width: 15%;
        }

        &:nth-child(3) {
          min-width: 300px;
          width: 22%;
        }

        &:nth-child(4) {
          min-width: 150px;
          width: 5%;
        }

        &:nth-child(5) {
          min-width: 200px;
          width: 15%;
        }
      }
    }

    tbody {
      td {
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .date-time-wrapper {
        position: relative;

        .date {
          font-weight: bold;
          font-size: 18px;
        }
      }

      .phase-wrapper {
      }

      .teams-wrapper {
        .team {
          height: 36px;
          display: flex;

          img {
            width: 30px;
            height: 30px;
          }

          .team-name {
            font-size: 20px;
            display: inline;
            vertical-align: middle;
            margin-left: 5px;
            line-height: 1;
            padding-top: 5px;

            &.winner {
              font-weight: 600;
            }
          }
        }

        .button-wrapper {
          margin-top: 20px;
          margin-right: 6px;
          display: inline;
          .get-tickets-btn {
            background-color: #6441ff;
            font-family: DrukWideItalic, Sans-serif;
            text-transform: uppercase;
            color: #fff;
            border-color: #6441ff;
            border-width: 2px;
            border-radius: 100px 100px 100px 100px;
            padding: 1px 15px;
            border-style: solid;
            text-decoration: none;
            font-size: 20px;
          }
        }
      }

      .score-wrapper {
        padding-right: 30px;

        a {
          color: #000;
          text-decoration: none;
        }

        .score {
          font-family: DrukWide;
          font-size: 24px;
          text-align: right;
        }

        .no-score {
          margin-top: 15px;
          margin-bottom: 32px;
          width: 100%;
          position: relative;
          height: 3px;

          &:after {
            content: "";
            position: absolute;
            right: 0;
            width: 25px;
            height: 3px;
            background: #000;
          }
        }
      }

      .venue-wrapper {
        position: relative;

        .city {
          font-size: 14px;
        }

        .venue {
          font-size: 18px;
        }
      }
    }
  }
}

.load-more-btn-wrapper {
  margin-top: 50px;
  text-align: center;

  .load-more-btn {
    display: inline-block;
    margin: 10px 0 20px;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
    fill: #fff;
    color: #fff;
    background-color: #6441ff;
    border-radius: 100px 100px 100px 100px;
    padding: 10px 30px;
    text-decoration: none;
    transition-duration: 0.3s;
    transition-property: transform;

    &:hover {
      background-color: #eb3ce7;
      transform: scale(0.9);
    }
  }
}
</style>
